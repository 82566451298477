@import url("https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&family=Kenia&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Roboto+Condensed:ital,wght@0,400;1,700&display=swap");
@tailwind base;
@tailwind utilities;
@tailwind components;

html,
body {
  padding: 0;
  margin: 0;
   /* font-family: "Kdam Thmor Pro", serif;  */
   font-family: "Times New Roman", Times, serif, Arial, sans-serif; 
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: rgb(227, 136, 38);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 91, 0);
}
@layer base{
  .nav{
    @apply relative text-gray-500 tracking-[1px]}
    
    .nav::after {
    @apply content-[''] bg-orange-500 h-[3px] w-[0%] left-0 -bottom-[5px] rounded-xl absolute duration-300}
    .nav:hover::after{
    @apply w-[100%]}
}



/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: white;
  }
} */
